import { db } from "utils/firebase";
import { addDoc, doc, collection, onSnapshot } from "firebase/firestore";

import initializeStripe from "./initializeStripe";
import { BASE_URL, BASE_URL_HOMEPAGE_SUCCESS } from "constants/redirects";

function getClientReferenceId() {
  return (
    // @ts-ignore
    (window.Rewardful && window.Rewardful.referral) ||
    "checkout_" + new Date().getTime()
  );
}

const createCheckoutSession = async (uid: string) => {
  // Create a new checkout session in the subcollection inside this users document
  const checkoutRefId = await addDoc(
    collection(db, `users/${uid}/checkout_sessions`),
    {
      price: "price_1N5evtFeZ1oQgYe4TK7g7F6B",
      success_url: BASE_URL_HOMEPAGE_SUCCESS,
      cancel_url: BASE_URL,
      clientReferenceId: getClientReferenceId(),
    }
  ).then((docRef) => docRef.id);

  onSnapshot(
    doc(db, `users/${uid}/checkout_sessions`, checkoutRefId),
    async (snap) => {
      // Wait for the CheckoutSession to get attached by the extension

      const { sessionId } = snap.data();
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe

        const stripe = await initializeStripe();
        stripe.redirectToCheckout({ sessionId });
      }
    }
  );
};

export { createCheckoutSession };
