import { db } from "utils/firebase";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  runTransaction,
  writeBatch,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

import { Post } from "models/Post";

const addPost = async ({ details }: { details?: Post }) => {
  try {
    let newPostCount = 0;
    let newGlobalPostCount = 0;

    await runTransaction(db, async (userTransaction) => {
      const userRef = doc(db, "users", details?.authorId);
      const userSnap = await userTransaction.get(userRef);

      if (!userSnap.exists()) {
        throw new Error("User does not exist!");
      }

      newPostCount = (userSnap.data()?.postCount || 0) + 1;
      userTransaction.update(userRef, { postCount: newPostCount });
    });

    await runTransaction(db, async (publicUserTransaction) => {
      const userRef = doc(db, "publicUsers", details?.authorId);
      const userSnap = await publicUserTransaction.get(userRef);

      if (!userSnap.exists()) {
        throw new Error("User does not exist!");
      }

      newPostCount = (userSnap.data()?.postCount || 0) + 1;
      publicUserTransaction.update(userRef, { postCount: newPostCount });
    });

    await runTransaction(db, async (globalTransaction) => {
      const globalRef = doc(db, "global", "statistics");
      const globalSnap = await globalTransaction.get(globalRef);

      if (!globalSnap.exists()) {
        throw new Error("Add global statistics to db first!");
      }

      newGlobalPostCount = (globalSnap.data()?.postCount || 0) + 1;
      globalTransaction.update(globalRef, { postCount: newGlobalPostCount });
    });

    const newPost = {
      ...details,
      userPostCounterId: newPostCount,
      globalPostCounterId: newGlobalPostCount,
      commentCount: 0,
      likeCount: 0,
      isArchived: false,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    return await addDoc(collection(db, "posts"), newPost).then(
      async (docRef) => {
        const subscribers = await getDocs(
          query(
            collection(db, "subscriptions"),
            where("subscriptionId", "==", details?.authorId)
          )
        );

        await setDoc(
          doc(db, `publicUsers/${details?.authorId}/feed`, docRef.id),
          newPost
        );

        if (subscribers.docs.length > 0) {
          const subscriptionUpdateBatch = writeBatch(db);

          subscribers.forEach((subscriber) => {
            if (subscriber?.data()?.subscriberId) {
              const subscriptionRef = doc(
                db,
                `publicUsers/${subscriber?.data()?.subscriberId}/feed`,
                docRef.id
              );
              subscriptionUpdateBatch.set(subscriptionRef, newPost);
            }
          });

          await subscriptionUpdateBatch.commit().then(() => docRef.id);
          return docRef.id;
        } else {
          return docRef.id;
        }
      }
    );
  } catch (err) {
    throw new Error("Not allowed ", err);
  }
};

const updatePost = async ({
  docId,
  details,
}: {
  docId: string;
  details?: Post;
}) => {
  try {
    const postRef = doc(db, "posts", docId);
    const docSnap = await getDoc(postRef);

    const newPost = {
      ...details,
      updatedAt: serverTimestamp(),
    };

    if (docSnap.exists()) {
      const subscribers = await getDocs(
        query(
          collection(db, "subscriptions"),
          where("subscriptionId", "==", docSnap.data().authorId)
        )
      );
      await updateDoc(postRef, newPost);
      await updateDoc(
        doc(db, `publicUsers/${docSnap.data().authorId}/feed`, docId),
        newPost
      );

      if (subscribers.docs.length > 0) {
        const subscriptionUpdateBatch = writeBatch(db);

        subscribers.forEach((subscriber) => {
          if (subscriber?.data()?.subscriberId) {
            const subscriptionRef = doc(
              db,
              `publicUsers/${subscriber?.data()?.subscriberId}/feed`,
              docId
            );
            subscriptionUpdateBatch.update(subscriptionRef, newPost);
          }
        });

        await subscriptionUpdateBatch.commit();
      }
    } else {
      await addPost({ details });
    }

    Promise.resolve();
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

const archivePost = async ({ docId }: { docId: string }) => {
  try {
    const postRef = doc(db, "posts", docId);
    const docSnap = await getDoc(postRef);

    if (docSnap.exists()) {
      const subscribers = await getDocs(
        query(
          collection(db, "subscriptions"),
          where("subscriptionId", "==", docSnap.data().authorId)
        )
      );
      await updateDoc(postRef, { isArchived: true });
      await updateDoc(
        doc(db, `publicUsers/${docSnap.data().authorId}/feed`, docId),
        { isArchived: true }
      );

      if (subscribers.docs.length > 0) {
        const subscriptionUpdateBatch = writeBatch(db);

        subscribers.forEach((subscriber) => {
          if (subscriber?.data()?.subscriberId) {
            const subscriptionRef = doc(
              db,
              `publicUsers/${subscriber?.data()?.subscriberId}/feed`,
              docId
            );
            subscriptionUpdateBatch.set(subscriptionRef, { isArchived: true });
          }
        });

        await subscriptionUpdateBatch.commit();
      }
    }

    Promise.resolve();
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

export { addPost, updatePost, archivePost };
