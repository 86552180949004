import { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "next/link";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/browser";
dayjs.extend(relativeTime);

import { db } from "utils/firebase";
import { useAuth } from "context/AuthContext";
import { handlePostLike } from "pages/api/like";
import { withBreaksAndLinks } from "utils/textUtils";
import { toast } from "react-toastify";
import { Post } from "models/Post";
import { User } from "models/User";
import { Avatar } from "components/common";
import { getInitials } from "utils/textUtils";
import ArticleSummary from "./ArticleSummary";
import AudioPlayer from "./AudioPlayer";
import VideoPlayer from "./VideoPlayer";
import ImageView from "./ImageView";
import PostInteractions from "./PostInteractions";
import useWindowSize from "hooks/window/useWindowSize";
import { mobile } from "config/media";
import {
  MAX_LIKES_PER_SECOND,
  SOFT_LIMIT_LIKES_PER_SECOND,
} from "constants/hardLimits";
import { normalizeDate } from "utils/dateUtils";

interface StandardPostProps {
  id: string;
  postData?: Post;
  userData?: User;
  isEmbedded?: boolean;
}

function StandardPost({
  id,
  postData,
  userData,
  isEmbedded = false,
}: StandardPostProps) {
  const { user } = useAuth();
  const router = useRouter();
  const routerQuery = router.query;

  // Temp hard limits
  const [firstLike, setFirstLike] = useState(null);
  const [numberOfLikes, setNumberOfLikes] = useState(0);

  const { width } = useWindowSize();
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "https://www.actnowbutton.com";

  const getPathname = () =>
    routerQuery?.profileId ? `/${routerQuery?.profileId}` : `/home`;

  const expandedPostLink = `${
    isEmbedded ? "/embed" : ""
  }${getPathname()}/posts/${id}`;

  const handleLikeClick = async () => {
    // Temp hard limits
    const now = new Date().getTime();

    if (!firstLike || now - 1000 >= firstLike) {
      setFirstLike(now);
      setNumberOfLikes(1);
    } else {
      if (numberOfLikes >= MAX_LIKES_PER_SECOND) {
        toast.error("`Please try again in 10 seconds.`");
        return false;
      } else {
        setNumberOfLikes((changes) => changes + 1);

        if (numberOfLikes >= SOFT_LIMIT_LIKES_PER_SECOND) {
          Sentry.captureMessage(`Soft limit likes reached - ${user.uid}`);
        }
      }
    }

    user ? await handlePostLike(id, user?.uid || "") : router.push("/login");
  };

  const [likeDoc] = useDocument(doc(db, "likes", `${id}_${user?.uid}`));

  const [postUpdate] = useDocument(doc(db, "posts", id));
  const postUpdateData = postUpdate?.data();

  return (
    <div className="relative flex w-full max-w-3xl" key={id}>
      <div className="flex w-full  flex-col px-4 pt-4 pb-2 hover:bg-gray-50">
        <div>
          <Link
            className="absolute left-0 top-0 h-full w-full cursor-pointer "
            href={expandedPostLink}
          />
          <div className="relative flex h-full items-start  justify-center">
            <div className="flex h-full flex-col items-start justify-start">
              <Link
                href={`/${userData?.username || ""}`}
                className="rounded-full border border-transparent hover:border-emerald-500"
              >
                <Avatar
                  initials={getInitials(userData?.name)}
                  imageUrl={userData?.profileImageUrl}
                  size={width < mobile ? "m" : "l"}
                />
              </Link>
              <Link
                href={expandedPostLink}
                className="h-full w-full cursor-pointer pr-3"
              ></Link>
            </div>
            <div className="flex h-full min-w-0 flex-1 flex-col items-start justify-start">
              <div className="flew-row flex w-full items-center justify-start">
                <Link
                  className="text-md cursor-pointer whitespace-nowrap  pl-3 text-sm font-medium text-gray-900 hover:underline  sm:text-base"
                  href={`/${userData?.username || ""}`}
                >
                  {userData?.name || "Anonymous"}
                </Link>

                <Link
                  href={expandedPostLink}
                  className="flex h-full w-full cursor-pointer items-center"
                >
                  <span className="ml-4 flex items-center justify-start whitespace-nowrap text-sm text-gray-600">
                    {dayjs(normalizeDate(postData?.createdAt)).fromNow()}
                  </span>

                  <div></div>
                </Link>
              </div>

              {postData?.shortTextContent ? (
                <p className="mb-0 pt-2 pb-5 pl-3 text-left text-sm font-normal text-black sm:text-base">
                  {withBreaksAndLinks(postData?.shortTextContent)}
                </p>
              ) : null}

              {postData?.articleContent ? (
                <Link
                  href={expandedPostLink}
                  className="w-full cursor-pointer pl-3"
                >
                  <div className="py-3">
                    <ArticleSummary
                      articleContent={postData?.articleContent}
                      articleTitle={postData?.articleTitle}
                    />
                  </div>
                </Link>
              ) : null}

              {postData?.audioSrc ? (
                <div className="pt-5 pl-3">
                  <AudioPlayer audioSrc={postData?.audioSrc} />
                </div>
              ) : null}

              {postData?.videoSrc ? (
                <div className="pt-5 pl-3">
                  <VideoPlayer videoSrc={postData?.videoSrc} />
                </div>
              ) : null}

              {postData?.imageSrc ? (
                <div className="pt-5 pl-3">
                  <ImageView imageSrc={postData?.imageSrc} />
                </div>
              ) : null}

              <PostInteractions
                commentLink={expandedPostLink}
                numberOfLikes={postUpdateData?.likeCount}
                numberOfComments={postUpdateData?.commentCount}
                onLikeClick={handleLikeClick}
                isLiked={likeDoc?.exists()}
                postUrl={`${origin}${getPathname()}/posts/${id}`}
                shareTextContent={
                  postData?.shortTextContent ||
                  postData?.articleTitle ||
                  "Check this out!"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StandardPost;
