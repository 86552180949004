import { useState, ChangeEvent, useEffect } from "react";

interface AutoSizeInputProps {
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const AutoSizeInput = ({ defaultValue, onChange }: AutoSizeInputProps) => {
  const [width, setWidth] = useState("0ch");

  useEffect(() => setWidth(`${defaultValue?.length}ch`), [defaultValue]);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setWidth(`${e.target.value.length}ch`);
    onChange && onChange(e.target.value);
  };

  return (
    <input
      style={{ width: `${width}` }}
      className={`my-[4px] box-content rounded-sm border-2 border-blue-300 bg-transparent p-0 text-xs text-gray-600 focus:m-0 focus:p-1`}
      type="text"
      autoFocus
      onChange={changeHandler}
      defaultValue={defaultValue}
    />
  );
};

export default AutoSizeInput;
