import { useState } from "react";
import Image from "next/image";
import Link from "next/link";

interface ActNowButtonProps {
  onClick?: () => void;
  link?: string;
  width?: number;
  height?: number;
  tabIndex?: number;
}

const ActNowButton = ({
  onClick,
  link,
  width = 50,
  height = 50,
  tabIndex,
}: ActNowButtonProps) => {
  const [isActionButtonPressed, setIsActionButtonPressed] = useState(false);

  const handleActionPress = () => setIsActionButtonPressed(true);

  const handleActionUnpress = () => setIsActionButtonPressed(false);

  const handleClick = () => onClick && onClick();

  const renderButtonImage = () => (
    <>
      {isActionButtonPressed ? (
        <Image
          src="/images/act-now-button-pressed.png"
          width={width}
          height={height}
          alt="Act Now Button pressed"
          priority
        />
      ) : (
        <Image
          src="/images/act-now-button.png"
          width={width}
          height={height}
          alt="Act Now Button"
          priority
        />
      )}
    </>
  );

  //   <!-- HTML !-->
  // <button class="button-54" role="button">Button 54</button>

  // /* CSS */
  // .button-54 {
  //   font-family: "Open Sans", sans-serif;
  //   font-size: 16px;
  //   letter-spacing: 2px;
  //   text-decoration: none;
  //   text-transform: uppercase;
  //   color: #000;
  //   cursor: pointer;
  //   border: 3px solid;
  //   padding: 0.25em 0.5em;
  //   box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  //   position: relative;
  //   user-select: none;
  //   -webkit-user-select: none;
  //   touch-action: manipulation;
  // }

  // .button-54:active {
  //   box-shadow: 0px 0px 0px 0px;
  //   top: 5px;
  //   left: 5px;
  // }

  // @media (min-width: 768px) {
  //   .button-54 {
  //     padding: 0.25em 0.75em;
  //   }
  // }

  //   <a style="
  //   cursor: pointer;
  //   width: 40px;
  //   height: 40px;
  //   font: 600 10px 'Inter', sans-serif;
  //   line-height: 0.98;
  //   text-align: center;
  //   text-decoration: none;
  //   overflow-wrap: break-word;
  //   display: inline-block;
  //   border: 1px solid #000;
  //   border-radius: 50%;
  //   padding-top: 7px;
  //   box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px;
  //   color: black;
  //   background-color: white;"
  //   href="${donateLink}"
  // >
  //   ACT NOW
  // </a>`);
  return (
    <div className="flex items-center justify-center">
      {link ? (
        <>
          <Link
            className="box-border inline-block h-[40px] w-[40px] cursor-pointer break-words rounded-full border border-black bg-white pt-[8px] text-center font-sans text-[10px] font-semibold leading-[0.98] text-black no-underline shadow-[1px_1px_0px_0px,_2px_2px_0px_0px]"
            tabIndex={tabIndex}
            href={link || "/"}
            target={link === "/" ? "" : "_blank"}
            onMouseDown={handleActionPress}
            onMouseUp={handleActionUnpress}
            onMouseLeave={handleActionUnpress}
            onClick={handleClick}
          >
            ACT NOW
          </Link>
        </>
      ) : (
        <button
          tabIndex={tabIndex}
          onMouseDown={handleActionPress}
          onMouseUp={handleActionUnpress}
          onMouseLeave={handleActionUnpress}
          onClick={handleClick}
          className=""
        >
          {renderButtonImage()}
        </button>
      )}
    </div>
  );
};

export default ActNowButton;
