import { useRouter } from "next/router";

import { TOPICS } from "constants/topics";
import { Topic } from "models/Topic";

interface RandomActionButtonProps {
  topic?: Topic;
}

const RandomActionButton = ({ topic }: RandomActionButtonProps) => {
  const router = useRouter();

  const handleGenerateActionPress = () => {
    const generateTopic = topic
      ? topic
      : TOPICS[Math.floor(Math.random() * TOPICS.length)];

    router.push(
      `/search/${encodeURI(generateTopic.name.split(" ").join("+"))}`
    );
  };

  return (
    <div className="mt-6">
      <button
        onClick={handleGenerateActionPress}
        className="mt-2 flex w-full items-center justify-center rounded-md border bg-gray-100 px-3 py-2 text-sm font-normal text-gray-900 opacity-70 shadow-sm hover:border-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:cursor-not-allowed  disabled:bg-gray-100 disabled:opacity-70 md:mt-0"
      >
        Generate Action
      </button>
    </div>
  );
};

export default RandomActionButton;
