import Image from "next/image";
import { useState } from "react";
import { useCopyToClipboard } from "react-use";

import { toast } from "react-toastify";
import { Switch } from "@headlessui/react";

import CustomActionButton from "./CustomActionButton";
import { Action } from "models/Action";
import { ActNowButton } from "components/common";
import { classNames } from "utils/classNames";

interface ActionItemsProps {
  title?: string;
  description?: string;
  homeLink?: string;
  code?: string;
}

const ActionItems = ({
  id,
  homeLink,
  donateLink,
  title,
  description,
  showSummary,
  code,
  paddingY,
  paddingX,
  borderRadius,
  borderSize,
  borderColor,
  backgroundColor,
  buttonText,
  buttonType,
  buttonLink,
}: Action & { showSummary?: boolean }) => {
  const [, copyToClipboard] = useCopyToClipboard();

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showHtmlLink, setShowHtmlLink] = useState(false);
  const [showCustomButton, setShowCustomButton] = useState(false);

  const [customButtonText, setCustomButtonText] = useState("Donate Now");
  const [customButtonFontSize, setCustomButtonFontSize] = useState("14");
  const [customButtonPaddingY, setCustomButtonPaddingY] = useState("5");
  const [customButtonPaddingX, setCustomButtonPaddingX] = useState("10");
  const [customButtonBorderRadius, setCustomButtonBorderRadius] =
    useState("20");
  const [customButtonBorderSize, setCustomButtonBorderSize] = useState("2");
  const [customButtonBorderColor, setCustomButtonBorderColor] =
    useState("black");
  const [customButtonBackgroundColor, setCustomButtonBackgroundColor] =
    useState("white");
  const [customButtonFontColor, setCustomButtonFontColor] = useState("black");

  const donateLinkCode = () => {
    if (showHtmlLink)
      return `<a href="${donateLink}
  target="_blank">
  ${title}
</a>`;
    return donateLink;
  };

  const donateButtonCopy = () =>
    showCustomButton
      ? `
      <a style="
        cursor: pointer;
        color: ${customButtonFontColor};
        background-color: ${customButtonBackgroundColor};
        font: 600 ${customButtonFontSize}px 'system-ui', sans-serif;
        padding: ${customButtonPaddingY}px ${customButtonPaddingX}px;
        border: ${customButtonBorderSize}px solid ${customButtonBorderColor};
        border-radius: ${customButtonBorderRadius}px;
        target="_blank"
      ">
        <span>${customButtonText}</span>
      </a>
    `
      : `
      <a style="
        cursor: pointer;
        width: 40px;
        height: 40px;
        font: 600 10px 'system-ui', sans-serif;
        line-height: 0.98;
        text-align: center;
        text-decoration: none;
        overflow-wrap: break-word;
        display: inline-block;
        border: 1px solid #000;
        border-radius: 50%;
        padding-top: 8px;
        box-sizing: border-box;
        box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px;
        color: black;
        background-color: white;"
        target="_blank"
        href="${donateLink}"
      >
        ACT NOW
      </a>
    `;

  const handleLinkCopy = () => {
    copyToClipboard(donateLinkCode());
    toast.success("Link copied");
  };

  const handleButtonCodeCopy = () => {
    copyToClipboard(donateButtonCopy());
    toast.success("Code copied");
  };

  const handleMoreOptionsClick = () => setShowMoreOptions(!showMoreOptions);
  const handleShowCustomButton = () => setShowCustomButton(!showCustomButton);

  return (
    <div
      className={`${
        showSummary ? "" : "border-b"
      } flex w-full max-w-2xl flex-col items-center justify-center overflow-y-visible py-4`}
    >
      <div className="flex w-full flex-col items-start justify-center overflow-y-visible">
        {showSummary ? (
          <div>
            <a
              href={homeLink}
              className="w-full cursor-pointer text-gray-500 underline"
            >
              <h3 className="text-sm font-bold">{title}</h3>
            </a>
            <p className="mt-2 w-full text-sm text-gray-500">{description}</p>
          </div>
        ) : (
          <>
            <div className="flex flex-row gap-x-4">
              <Image
                src={`${
                  homeLink
                    ? `https://www.google.com/s2/favicons?sz=32&domain=${homeLink}`
                    : "/favicon.ico"
                }`}
                alt={`${title} icon`}
                width={32}
                height={32}
              />
              <a
                href={homeLink}
                className="cursor-pointer text-blue-600 hover:underline"
              >
                <h3 className="text-2xl font-bold">{title}</h3>
              </a>
            </div>
            <p className="mt-2 w-full text-sm text-gray-500">{description}</p>
          </>
        )}

        <div className="mt-6 flex w-full flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-x-6 ">
            <div className="text-sm text-gray-500">Copy code for website:</div>

            <button
              onClick={handleLinkCopy}
              className=" flex items-center justify-center rounded-md border bg-gray-100 py-1 px-3 text-sm font-normal text-gray-900 opacity-70 shadow-sm hover:border-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:cursor-not-allowed  disabled:bg-gray-100 disabled:opacity-70 md:mt-0"
            >
              Copy donate link
            </button>

            <button
              onClick={handleButtonCodeCopy}
              className=" flex items-center justify-center rounded-md border bg-gray-100 py-1 px-3 text-sm font-normal text-gray-900 opacity-70 shadow-sm hover:border-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:cursor-not-allowed  disabled:bg-gray-100 disabled:opacity-70 md:mt-0"
            >
              Copy button code
            </button>
          </div>

          <button
            className="flex cursor-pointer items-center justify-end rounded-md hover:scale-105 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            onClick={handleMoreOptionsClick}
          >
            <div className="sr-only">More options</div>
            <div
              className={`${
                showMoreOptions ? "rotate-180" : " rotate-0"
              } ml-2 text-base transition-all only:mb-0`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#9ca3af"
                  d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0L6.7 10.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z"
                />
              </svg>
            </div>
          </button>
        </div>

        <div
          className={`${
            showMoreOptions ? "max-h-[1000px]" : "max-h-0"
          }  w-full overflow-x-hidden overflow-y-visible transition-all`}
        >
          <div className="mt-8 flex w-full flex-col overflow-y-visible pb-2 prose-h4:text-gray-500 prose-pre:bg-gray-100 prose-pre:p-2 prose-pre:text-xs prose-pre:text-gray-600">
            <div className="flex w-full flex-row items-start justify-between">
              <h4 className="">Donate link</h4>

              <div className="ml-3 flex flex-col items-end ">
                <label
                  className="whitespace-nowrap text-right text-sm text-gray-500"
                  htmlFor="htmlLinkToggle"
                >
                  HTML link
                </label>
                <Switch
                  id="htmlLinkToggle"
                  checked={showHtmlLink}
                  onChange={setShowHtmlLink}
                  className={classNames(
                    showHtmlLink ? "bg-emerald-600" : "bg-gray-200",
                    "relative mt-1 mb-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showHtmlLink ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
            </div>

            <button
              onClick={handleLinkCopy}
              className="w-auto cursor-pointer overflow-auto"
            >
              <pre className="w-min whitespace-pre-wrap text-left">
                <code className="w-auto">{donateLinkCode()}</code>
              </pre>
            </button>

            <div className="mt-8 flex w-full flex-row items-start justify-between overflow-y-visible">
              <h4 className="">Custom button</h4>

              <div className="ml-3 flex flex-col items-end ">
                <label
                  className="whitespace-nowrap text-right text-sm text-gray-500"
                  htmlFor="htmlLinkToggle"
                >
                  Custom button
                </label>
                <Switch
                  id="htmlLinkToggle"
                  checked={showCustomButton}
                  onChange={setShowCustomButton}
                  className={classNames(
                    showCustomButton ? "bg-emerald-600" : "bg-gray-200",
                    "relative mt-1 mb-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showCustomButton ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
            </div>

            {showCustomButton ? (
              <CustomActionButton
                buttonLink={donateLink}
                buttonText={customButtonText}
                buttonType={buttonType}
                fontSize={customButtonFontSize}
                paddingY={customButtonPaddingY}
                paddingX={customButtonPaddingX}
                borderRadius={customButtonBorderRadius}
                borderSize={customButtonBorderSize}
                borderColor={customButtonBorderColor}
                backgroundColor={customButtonBackgroundColor}
                fontColor={customButtonFontColor}
                setButtonText={setCustomButtonText}
                setFontSize={setCustomButtonFontSize}
                setPaddingY={setCustomButtonPaddingY}
                setPaddingX={setCustomButtonPaddingX}
                setBorderRadius={setCustomButtonBorderRadius}
                setBorderSize={setCustomButtonBorderSize}
                setBorderColor={setCustomButtonBorderColor}
                setBackgroundColor={setCustomButtonBackgroundColor}
                setFontColor={setCustomButtonFontColor}
                handleButtonCodeCopy={handleButtonCodeCopy}
              />
            ) : (
              <ActNowButton link={donateLink} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionItems;

{
  /* <button
onClick={() => setShowOptions(!showOptions)}
className={`${
  showOptions ? "mt-1  rotate-180" : "mt-0  rotate-0"
} mr-4 ml-8 p-4 text-xl font-bold transition-all only:mb-0`}
>
∨
</button> */
}

// <div className={`${showOptions ? "max-h-[1000px]" : "max-h-0"}`}>
// More options
// </div>
