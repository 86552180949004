import { useState, SyntheticEvent, FormEvent, Fragment } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";

import { useAuth } from "context/AuthContext";
import { toast } from "react-toastify";
import BasicToast from "components/common/Layout/BasicToast";
import { ErrorWithCode } from "models/ErrorWithCode";
import LinkToast, { showLinkToast } from "components/common/Layout/LinkToast";
import { TOAST_IDS } from "constants/toastConstants";
import { addToWaitlist } from "pages/api/user";
import { ERROR_CODES } from "constants/errorCodes";
import { FirebaseError } from "firebase/app";
import { BASE_URL } from "constants/redirects";
import { subscribeToUser } from "pages/api/subscription";

interface LoginPopupProps {
  handleRegisterClick: () => void;
  setOpen: (open: boolean) => void;
}

function LoginPopup({ handleRegisterClick, setOpen }: LoginPopupProps) {
  const router = useRouter();
  const routerPath = router.pathname;
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [shouldRememberCheckbox, setShouldRememberCheckbox] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    login,
    getGoogleCredentials,
    setShouldRemember,
    logout,
    validateUser,
  } = useAuth();

  const handleSubmitEmail = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    setShouldRemember(shouldRememberCheckbox);

    setIsLoading(true);

    await login(email, password)
      .then(() => {
        setOpen(false);
      })
      .catch(async (err) => {
        // WAITLIST DISABLED
        // if (err instanceof ErrorWithCode) {
        //   const waitlistUser = await addToWaitlist(email);

        //   toast.success(
        //     `You're on the waitlist! Position: ${waitlistUser.priority}`,
        //     {
        //       containerId: TOAST_IDS.basicToastId,
        //     }
        //   );
        //   return;
        // }

        if (err instanceof ErrorWithCode) {
          if (err.code === ERROR_CODES.notFound) {
            showLinkToast(
              "info",
              "Account does not exist. Click here to register instead.",
              `${BASE_URL}/register`
            );

            setIsLoading(false);
            return;
          }
        }

        console.error(err);
        setIsLoading(false);
        toast.error("Something went wrong", {
          containerId: TOAST_IDS.basicToastId,
        });
      });
  };

  const handleGmailLogin = async () => {
    setShouldRemember(shouldRememberCheckbox);
    setIsLoading(true);

    try {
      const credentials = await getGoogleCredentials();

      setOpen(false);

      // WAITLIST DISABLED
      // if (!(await validateUser(credentials))) {
      //   const waitlistUser = await addToWaitlist(email);

      //   toast.info(
      //     `You're on the waitlist! Position: ${waitlistUser.priority}`,
      //     {
      //       containerId: TOAST_IDS.basicToastId,
      //     }
      //   );
      //   return;
      // }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      toast.error("Something went wrong", {
        containerId: TOAST_IDS.basicToastId,
      });
    }
  };

  const handleEmailChange = (e: FormEvent<HTMLInputElement>) =>
    setEmail(e.currentTarget.value);

  const handlePasswordChange = (e: FormEvent<HTMLInputElement>) =>
    setPassword(e.currentTarget.value);

  const handleShouldRememberChange = (e: FormEvent<HTMLInputElement>) =>
    setShouldRememberCheckbox(e.currentTarget.checked);

  const hasEmailAndPassword = () => email && password;

  return (
    <>
      <div className="mt-14 flex min-h-full w-full flex-col items-center justify-center overflow-y-auto py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-14 rounded-md px-4 py-2 text-center  text-lg font-light tracking-tight text-black sm:text-2xl">
            Continue to
          </h2>
          <Image
            className="mx-auto mt-2 h-12 w-auto sm:h-16"
            src="/images/act-now-button.png"
            alt="Act Now Button Logo"
            width={40}
            height={40}
            priority
          />
        </div>

        <div className="mt-8 w-full sm:mx-auto sm:mb-8 sm:w-full sm:max-w-md">
          <div className="py-8 px-4 sm:rounded-lg sm:bg-white sm:px-10 sm:shadow">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmitEmail}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                    onChange={handleEmailChange}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                    onChange={handlePasswordChange}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                    onChange={handleShouldRememberChange}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
              </div>

              <div>
                <button
                  disabled={!hasEmailAndPassword()}
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-emerald-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-600 focus:border-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-emerald-500 disabled:opacity-70"
                >
                  Sign in
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-3">
                <div>
                  <button
                    onClick={handleGmailLogin}
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Google</span>
                    <svg
                      className="h-5 w-5 text-gray-500"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M17.788 5.108A9 9 0 1021 12h-8" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="text-center text-xs text-gray-400">
              Or{" "}
              <button
                type="button"
                onClick={handleRegisterClick}
                className="cursor-pointer underline"
              >
                register
              </button>{" "}
              an account
            </div>
          </div>
        </div>
      </div>
      <BasicToast enableMultiToast={true} />
      <LinkToast enableMultiToast={true} />
    </>
  );
}

export default LoginPopup;
