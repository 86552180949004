import ActionItem from "./ActionItem";
import { Action } from "models/Action";
import { AddAction } from "components/common";

interface ActionListProps {
  actions?: Action[];
}

const ActionList = ({ actions }: ActionListProps) => {
  return (
    <div className="flex w-full flex-col px-4 sm:px-6 md:px-8">
      <div className="mt-8 flex w-full flex-col items-center justify-center space-y-8">
        {actions?.length >= 1 ? (
          actions?.map((action) => (
            <ActionItem
              key={action?.id || action.title}
              title={action?.title}
              homeLink={action?.homeLink}
              donateLink={action?.donateLink}
              description={action?.description}
              code={action?.code}
              id={action?.id}
              paddingX={action?.paddingX}
              paddingY={action?.paddingY}
              borderRadius={action?.borderRadius}
              backgroundColor={action?.backgroundColor}
              buttonText={action?.buttonText}
              borderColor={action?.borderColor}
              borderSize={action?.borderSize}
              buttonLink={action?.buttonLink}
            />
          ))
        ) : (
          <div className="justify-content items-content flex flex-col">
            <h2 className="mt-10 w-full text-2xl font-bold text-gray-900">
              No actions found
            </h2>
          </div>
        )}
      </div>

      <AddAction />
    </div>
  );
};

export default ActionList;
