import Link from "next/link";
import { ChatBubbleOvalLeftIcon, HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as SolidHeartIcon } from "@heroicons/react/24/solid";
import { ShareSocials } from "components/common";

interface PostInteractionsProps {
  numberOfComments?: number;
  numberOfLikes?: number;
  isLiked?: boolean;
  onCommentClick?: () => void;
  commentLink?: string;
  onLikeClick?: () => void;
  postUrl?: string;
  shareTextContent?: string;
}

function PostInteractions({
  numberOfComments = 0,
  numberOfLikes = 0,
  isLiked = false,
  onCommentClick,
  commentLink,
  onLikeClick,
  postUrl,
  shareTextContent,
}: PostInteractionsProps) {
  return (
    <div className="mt-2 flex w-full items-center justify-between">
      <button
        className="group/like flex w-full items-center justify-center rounded-md py-1 text-gray-500 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:py-2"
        onClick={onLikeClick}
      >
        {isLiked ? (
          <SolidHeartIcon className="h-4 w-4 text-emerald-400 group-hover/like:text-emerald-600 sm:h-5 sm:w-5" />
        ) : (
          <HeartIcon className="h-4 w-4 text-gray-500 group-hover/like:text-emerald-600 sm:h-5 sm:w-5" />
        )}
        <span className="ml-1 text-sm group-hover/like:text-emerald-600">
          {numberOfLikes}
        </span>
      </button>

      {commentLink ? (
        <Link
          className="group/comment flex w-full items-center justify-center rounded-md py-1 text-gray-500  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-emerald-500  focus:ring-offset-2 sm:py-2"
          href={commentLink}
        >
          <ChatBubbleOvalLeftIcon className="h-4 w-4 text-gray-500 group-hover/comment:text-emerald-600 sm:h-5 sm:w-5" />
          <span className="ml-1 text-sm group-hover/comment:text-emerald-600">
            {numberOfComments}
          </span>
        </Link>
      ) : (
        <button
          className="group/comment flex w-full items-center justify-center rounded-md py-1 text-gray-500  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-emerald-500  focus:ring-offset-2 sm:py-2"
          onClick={onCommentClick}
        >
          <ChatBubbleOvalLeftIcon className="h-4 w-4 text-gray-500 group-hover/comment:text-emerald-600 sm:h-5 sm:w-5" />
          <span className="ml-1 text-sm group-hover/comment:text-emerald-600">
            {numberOfComments}
          </span>
        </button>
      )}

      <div className="group/share flex w-full items-center justify-center rounded-md text-gray-500 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2">
        <ShareSocials
          linkDetails={{ url: postUrl }}
          twitterDetails={{
            url: postUrl,
            title: shareTextContent,
          }}
          facebookDetails={{
            url: postUrl,
            quote: shareTextContent,
          }}
          whatsappDetails={{
            url: postUrl,
            title: shareTextContent,
          }}
          redditDetails={{ url: postUrl, title: shareTextContent }}
          emailDetails={{
            url: postUrl,
            subject: shareTextContent,
            body: postUrl,
          }}
        />
      </div>
    </div>
  );
}

export default PostInteractions;
