import { PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

import { AutoSizeInput } from "components/common";

interface CustomActionButtonProps {
  buttonText?: string;
  fontSize?: string;
  paddingY?: string;
  paddingX?: string;
  borderRadius?: string;
  borderSize?: string;
  borderColor?: string;
  backgroundColor?: string;
  fontColor?: string;
  buttonType?: string;
  buttonLink?: string;
  setButtonText?: (value: string) => void;
  setFontSize?: (value: string) => void;
  setPaddingY?: (value: string) => void;
  setPaddingX?: (value: string) => void;
  setBorderRadius?: (value: string) => void;
  setBorderSize?: (value: string) => void;
  setBorderColor?: (value: string) => void;
  setBackgroundColor?: (value: string) => void;
  setFontColor?: (value: string) => void;
  handleButtonCodeCopy?: () => void;
}

// position: absolute;
// 	background-color: ${({ highlightBackground }) =>
// 		highlightBackground ? blue : null};
// 	padding: ${({ padding }) => padding || "10px 25px"};
// 	border-radius: ${({ borderRadius }) => borderRadius || "20px"};
// 	border: ${({ border }) => border || "2px solid black"};
// 	box-shadow: ${({ highlightBorder }) =>
// 		highlightBorder ? `inset 0 0 0 4px ${blue}, 0 0 0 4px ${blue}` : null};
// 	${({ highlightBorderRadius }) =>
// 		highlightBorderRadius &&
//         css`
//             box-shadow: 25px 0 0 -16px white,
//                -25px 0 0 -16px white,
//                0 24px 0 -18px white,
//                0 -24px 0 -18px white,
//                0 0 0 4px ${blue};
// 		`}

// 	> span {
// 		background-color: ${({ highlightText }) =>
// 			highlightText ? blue : null};
// 	}

// 	cursor: pointer;
// 	opacity: 0.8;

const CustomActionButton = ({
  buttonText = "Donate Now",
  fontSize = "12",
  paddingY = "5",
  paddingX = "10",
  borderRadius = "20",
  borderSize = "2",
  borderColor = "black",
  backgroundColor = "white",
  fontColor = "black",
  buttonType,
  buttonLink,
  setButtonText,
  setFontSize,
  setPaddingY,
  setPaddingX,
  setBorderRadius,
  setBorderSize,
  setBorderColor,
  setBackgroundColor,
  setFontColor,
  handleButtonCodeCopy,
}: CustomActionButtonProps) => {
  const [highlightBackground, setHighlightBackground] = useState(false);
  const [highlightBorder, setHighlightBorder] = useState(false);
  const [highlightText, setHighlightText] = useState(false);
  const [highlightBorderRadius, setHighlightBorderRadius] = useState(false);

  const [showBackgroundColorPicker, setShowBackgroundColorPicker] =
    useState(false);
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const [showFontColorPicker, setShowFontColorPicker] = useState(false);

  const renderButtonStyles = () => ({
    padding: `${paddingY}px ${paddingX}px`,
    borderRadius: `${borderRadius}px`,
    border: `${borderSize}px solid ${borderColor || "black"}`,
    backgroundColor: `${highlightBackground ? "#93c5fd" : backgroundColor}`,
    color: `${fontColor || "black"}`,
    fontSize: `${fontSize}px`,
  });

  const renderHighlightButtonStyles = () => ({
    padding: `${paddingY}px ${paddingX}px`,
    borderRadius: `${borderRadius}px`,
    border: `${borderSize}px solid ${borderColor}`,
    backgroundColor: `${highlightBackground ? "#93c5fd" : backgroundColor}`,
    color: `${fontColor}`,
    fontSize: `${fontSize}px`,
  });

  const handleSetBackgroundColor = (value) => {
    setShowBackgroundColorPicker(false);
    setBackgroundColor(value.hex);
  };

  const handleSetBorderColor = (value) => {
    setShowBorderColorPicker(false);
    setBorderColor(value.hex);
  };

  const handleSetFontColor = (value) => {
    setShowFontColorPicker(false);
    setFontColor(value.hex);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <button
          type="button"
          className="mr-6 rounded-md bg-gray-100 px-2 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-emerald-400 disabled:opacity-70"
          onClick={handleButtonCodeCopy}
        >
          <span className="sr-only">Copy button code</span>
          <DocumentDuplicateIcon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="relative flex h-auto w-auto items-center justify-center px-4">
          <a
            style={renderButtonStyles()}
            className={` cursor-pointer whitespace-nowrap`}
          >
            <span>{buttonText}</span>
          </a>
          <div
            style={renderHighlightButtonStyles()}
            className={`${highlightBackground ? "bg-blue-300" : null} ${
              highlightBorder
                ? "shadow-[inset_0px_0px_0px_4px_#93c5fd,0px_0px_0px_4px_#93c5fd]"
                : null
            } absolute cursor-pointer whitespace-nowrap ${
              highlightBorderRadius
                ? "shadow-[25px_0px_0px_-12px_white,-25px_0px_0px_-12px_white,0px_24px_0px_-12px_white,0px_-24px_0px_-12px_white,0px_0px_0px_4px_#93c5fd]"
                : null
            } `}
          >
            <span className={`${highlightText ? "bg-blue-300" : null}`}>
              {buttonText}
            </span>
          </div>
        </div>

        <div className="invisible mr-6 rounded-md bg-gray-100 px-2 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-emerald-400 disabled:opacity-70">
          <DocumentDuplicateIcon className="h-6 w-6" aria-hidden="true" />
        </div>
      </div>

      <pre className="mt-6">
        <code>
          {`<a style="`}
          <div
            className="relative w-min py-1"
            onMouseOver={() => setHighlightText(true)}
            onMouseOut={() => setHighlightText(false)}
          >
            {`  color: `}
            <span className="relative w-min">
              <button
                className="w-min rounded-sm border-2 border-blue-200"
                onClick={() => setShowFontColorPicker(!showFontColorPicker)}
              >
                {fontColor}
              </button>
              {showFontColorPicker && (
                <div className="absolute bottom-[20px] left-1/2 z-50 -translate-x-1/3">
                  <SketchPicker
                    color={fontColor || undefined}
                    onChange={handleSetFontColor}
                  />
                </div>
              )}
            </span>
            {`;`}
          </div>

          <div
            className="relative w-min py-1"
            onMouseOver={() => setHighlightBackground(true)}
            onMouseOut={() => setHighlightBackground(false)}
          >
            {`  background-color: `}
            <span className="relative w-min">
              <button
                className="rounded-sm border-2 border-blue-200"
                onClick={() =>
                  setShowBackgroundColorPicker(!showBackgroundColorPicker)
                }
              >
                {backgroundColor}
              </button>
              {showBackgroundColorPicker && (
                <div className="absolute bottom-[20px] left-1/2 z-50 -translate-x-1/2">
                  <SketchPicker
                    color={backgroundColor || undefined}
                    onChange={handleSetBackgroundColor}
                  />
                </div>
              )}
            </span>
            {`;`}
          </div>

          <div
            onMouseOver={() => setHighlightText(true)}
            onMouseOut={() => setHighlightText(false)}
          >
            {`  font: 600 `}
            <AutoSizeInput
              defaultValue={fontSize}
              onChange={(value) => setFontSize(value)}
            />
            {`px 'system-ui', sans-serif;`}
          </div>

          <div
            onMouseOver={() => setHighlightBackground(true)}
            onMouseOut={() => setHighlightBackground(false)}
          >
            {`  padding: `}
            <AutoSizeInput
              defaultValue={paddingY}
              onChange={(value) => setPaddingY(value)}
            />
            {`px `}
            <AutoSizeInput
              defaultValue={paddingX}
              onChange={(value) => setPaddingX(value)}
            />
            {`px;`}
          </div>

          <div
            onMouseOver={() => setHighlightBorder(true)}
            onMouseOut={() => setHighlightBorder(false)}
          >
            {`  border: `}
            <AutoSizeInput
              defaultValue={borderSize}
              onChange={(value) => setBorderSize(value)}
            />
            {`px solid `}
            <span className="relative w-min">
              <button
                onClick={() => setShowBorderColorPicker(!showBorderColorPicker)}
                className="rounded-sm border-2 border-blue-200"
              >
                {borderColor}
              </button>
              {showBorderColorPicker && (
                <div className="absolute bottom-[20px] left-1/2 z-50 -translate-x-1/2">
                  <SketchPicker
                    color={borderColor || undefined}
                    onChange={handleSetBorderColor}
                  />
                </div>
              )}
            </span>
            {`;`}
          </div>

          <div
            onMouseOver={() => setHighlightBorderRadius(true)}
            onMouseOut={() => setHighlightBorderRadius(false)}
          >
            {`  border-radius: `}
            <AutoSizeInput
              defaultValue={borderRadius}
              onChange={(value) => setBorderRadius(value)}
            />
            {`px;`}
          </div>

          {`">`}
          <div
            onMouseOver={() => setHighlightText(true)}
            onMouseOut={() => setHighlightText(false)}
          >
            {`  <span>`}
            <AutoSizeInput
              defaultValue={buttonText}
              onChange={(value) => setButtonText(value)}
            />
            {`</span>`}
          </div>
          {`</a>`}
        </code>
      </pre>
    </div>
  );
};

export default CustomActionButton;
