import { Fragment } from "react";
import editorJSHTML from "editorjs-html";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";

interface ArticleSummaryProps {
  articleContent?: string;
  articleTitle?: string;
}

function ArticleSummary({ articleContent, articleTitle }: ArticleSummaryProps) {
  const getHTMLContent = () => {
    try {
      return editorJSHTML().parse(JSON.parse(articleContent));
    } catch {
      (err) => {
        throw err;
      };
    }
  };

  const renderArticleContent = () => {
    const htmlContent = getHTMLContent();
    if (htmlContent)
      return htmlContent.slice(0, 10).map((block) => {
        if (typeof block !== "string") return null;

        const isCodeBlock = block.startsWith("<pre><code>");

        if (isCodeBlock) {
          const strippedCodeBlock = block
            .replace("<pre><code>", "")
            .replace("</code></pre>", "");
          return (
            <Fragment key={block}>
              <pre>
                <code>
                  <Interweave
                    noWrap
                    escapeHtml={true}
                    content={strippedCodeBlock}
                  />
                </code>
              </pre>
            </Fragment>
          );
        }

        return (
          <Fragment key={block}>
            <Interweave
              noWrap
              content={block}
              matchers={[new UrlMatcher("url")]}
            />
          </Fragment>
        );
      });

    return null;
  };

  return (
    <div className="relative rounded-md border border-gray-200">
      <div className="absolute h-full w-full rounded-md bg-gradient-to-b from-transparent to-white " />
      <div className=" flex h-[12rem] w-full flex-col overflow-hidden  p-5">
        {articleTitle ? (
          <div className="mb-5 text-lg font-bold sm:text-xl">
            {articleTitle}
          </div>
        ) : null}
        <article className="prose">{renderArticleContent()}</article>
      </div>
    </div>
  );
}

export default ArticleSummary;
