import { useState, SyntheticEvent, RefObject } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { USER_VALIDATIONS } from "constants/validationRules";
import { PublishButton } from "components/common";
import { useLeavePageConfirm } from "utils/customHooks";
import { BASE_TEXT } from "constants/editorTools";

const EditorJs = dynamic(() => import("components/Post/Editor"), {
  ssr: false,
});

interface ExpandedModalProps {
  articleTitleRef?: RefObject<HTMLInputElement>;
  onCloseClick: () => void;
  onPublishClick?: (articleContent: string, articleTitle?: string) => void;
  defaultContent?: string;
  isLoading?: boolean;
}

function ExpandedModal({
  articleTitleRef,
  onCloseClick,
  onPublishClick,
  defaultContent,
  isLoading = false,
}: ExpandedModalProps) {
  const [articleContent, setArticleContent] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [articleMaxLengthReached, setArticleMaxLengthReached] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const onArticleChange = (currentArticleContent: string, title?: string) => {
    if (
      currentArticleContent !== null &&
      currentArticleContent.length > USER_VALIDATIONS.articlePostMaxLength
    )
      setArticleMaxLengthReached(true);
    else setArticleMaxLengthReached(false);

    if (currentArticleContent !== null)
      setArticleContent(
        currentArticleContent.substring(
          0,
          USER_VALIDATIONS.articlePostMaxLength
        )
      );

    if (title !== null) setArticleTitle(title);
  };

  const hasChanges = () =>
    !!articleContent && JSON.parse(articleContent)?.blocks?.length;

  const handlePublishClick = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPublishing(true);
    onPublishClick(articleContent, articleTitle);
  };

  useLeavePageConfirm((!!articleContent || !!articleTitle) && !isPublishing);

  return (
    <form
      className="absolute top-0 left-0 right-0 mx-6 mt-4 box-border h-screen pb-20"
      action="#"
      method="POST"
      onSubmit={handlePublishClick}
    >
      <div className="absolute top-0 left-0 mb-8 flex w-full flex-row justify-between">
        <div className=" sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            onClick={onCloseClick}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="sm:block">
          <PublishButton
            disabled={!hasChanges() || isLoading || articleMaxLengthReached}
            isLoading={isLoading}
          />
        </div>
      </div>

      <div className="mt-14 box-content flex h-[calc(100vh-80px)] flex-row overflow-auto">
        <label
          htmlFor="content"
          className="text-m sr-only block font-medium text-black"
        >
          Content
        </label>
        <div className=" ml-2 h-full w-full sm:ml-4">
          <EditorJs
            articleTitleRef={articleTitleRef}
            defaultText={defaultContent}
            readOnly={false}
            onChange={onArticleChange}
          />
        </div>
      </div>
    </form>
  );
}

export default ExpandedModal;
