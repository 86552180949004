import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import InfiniteScroll from "react-infinite-scroll-component";

import { StandardPost } from "components/common";

interface FeedProps {
  postsData?: any[];
  usersData?: any[];
  fetchNextData?: () => void;
  hasMoreData?: boolean;
  isEmbedded?: boolean;
}

function Feed({
  postsData,
  usersData,
  fetchNextData,
  hasMoreData = false,
  isEmbedded = false,
}: FeedProps) {
  const renderData = () => (
    <>
      {postsData &&
        postsData.map((post, index) => {
          const postData = post?.data();
          const authorData = usersData
            ? usersData.find((userData) => userData?.id === postData?.authorId)
            : null;

          const isLastItem = index >= postsData?.length - 1;

          return (
            <div
              key={post.id}
              className={`relative flex h-fit w-full ${
                isLastItem ? "border-none" : "border-b"
              } border-gray-200`}
            >
              {postData ? (
                <>
                  <StandardPost
                    id={post.id}
                    postData={postData}
                    userData={authorData?.data()}
                    isEmbedded={isEmbedded}
                  />
                </>
              ) : null}
            </div>
          );
        })}
    </>
  );
  return (
    <div className="mt-3 mb-24 w-full sm:mt-6 ">
      {postsData?.length ? (
        <InfiniteScroll
          dataLength={postsData.length}
          next={fetchNextData}
          hasMore={hasMoreData}
          style={{ overflow: "visible" }}
          loader={
            <div className="mt-2 flex justify-center">Loading more...</div>
          }
        >
          {renderData()}
        </InfiniteScroll>
      ) : (
        <div className="mt-4 flex w-full justify-center">
          Nothing to see here
        </div>
      )}
    </div>
  );
}

export default Feed;
