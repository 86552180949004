export const TOPICS = [
	{ id: 1, name: 'Climate change' },
	{ id: 2, name: 'Gender equality' },
	{ id: 3, name: 'Water contamination' },
	{ id: 4, name: 'Conflict in Ukraine' },
	{ id: 5, name: 'Poverty' },
	{ id: 6, name: 'Drug abuse' },
	{ id: 7, name: 'Child abuse' },
	{ id: 8, name: 'Animal abuse' },
	{ id: 9, name: 'Global warming' },
	{ id: 10, name: 'Racism' },
  ]
