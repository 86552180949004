import { auth } from "utils/firebase";

const isUserPremium = async (): Promise<boolean> => {
  await auth.currentUser?.getIdToken(true);
  const decodedToken = await auth.currentUser?.getIdTokenResult();

  return decodedToken?.claims?.stripeRole === "premium" ? true : false;
};

export { isUserPremium };
