import React, { ReactNode } from "react";

import Navbar from "./Navbar";
import BasicToast from "./BasicToast";

interface LayoutProps {
  isFullScreen?: boolean;
  hideNav?: boolean;
  children: ReactNode;
}

function Layout({
  isFullScreen = false,
  hideNav = false,
  children,
}: LayoutProps) {
  return (
    <div className="relative flex min-h-screen flex-col overflow-auto sm:flex-row">
      <main
        className={`w-full ${
          isFullScreen || hideNav ? "mt-0 md:ml-0" : "mt-10 md:ml-60 "
        } md:mt-0`}
      >
        {children}
      </main>
      {!hideNav && <Navbar isFullScreen={isFullScreen} />}
      <BasicToast />
    </div>
  );
}

export default Layout;
