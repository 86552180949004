import Image from "next/image";
import Loader from "./Loader";

interface PublishButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

function PublishButton({ disabled, onClick, isLoading }: PublishButtonProps) {
  return (
    <>
      {isLoading ? (
        <Loader width={30} height={30} />
      ) : (
        <button
          disabled={disabled}
          onClick={onClick}
          type="submit"
          className="flex w-full max-w-3xl flex-wrap content-center items-center justify-center rounded-full border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-emerald-600 disabled:opacity-70 sm:text-base"
        >
          Publish
        </button>
      )}
    </>
  );
}

export default PublishButton;
