import {
  addDoc,
  collection,
  setDoc,
  doc,
  getDoc,
  serverTimestamp,
} from "@firebase/firestore";
import axios from "axios";
import { RAY_EMAIL } from "constants/contacts";

import { Action } from "models/Action";
import { db } from "utils/firebase";

// TODO fix auth on this
// export default async function handler(req, res) {
//   try {
//     if (req.method === "POST") {
//       const { authorId, title, description, homeLink, donateLink } = req.body;
//       console.log(req.body);
//       console.log("title: ", title);

//       const newActionRef = doc(collection(db, "actions"));

//       //   await axios.post("/api/sendgrid", {
//       //     body: JSON.stringify({
//       //       to: RAY_EMAIL,
//       //       from: {
//       //         email: RAY_EMAIL,
//       //         name: authorId,
//       //       },
//       //       subject: `New action from ${authorId}`,
//       //       text: `Title: ${title} /n Description: ${description} /n Home Link: ${homeLink} /n Donate Link: ${donateLink}`,
//       //       html: `<div><p>Title: ${title}</p><p>Description: ${description}</p><p>Home Link: ${homeLink}</p><p>Donate Link: ${donateLink}</p></div>`,
//       //     }),
//       //     headers: {
//       //       "Content-Type": "application/json",
//       //     },
//       //     method: "POST",
//       //   });

//       await setDoc(newActionRef, {
//         title,
//         description,
//         homeLink,
//         donateLink,
//         updatedAt: serverTimestamp(),
//         createdAt: serverTimestamp(),
//         authorId,
//       });
//     }

//     return res.status(200).json({ message: "Success" });
//   } catch (error) {
//     console.log(error);
//     return res.status(500).json({ message: `Error: ${error}` });
//   }
// }

const addAction = async ({ details }: { details?: Action }) => {
  try {
    return await addDoc(collection(db, "actions"), {
      ...details,
      createdAt: serverTimestamp(),
    }).then(async (docRef) => {
      const { authorId, title, description, homeLink, donateLink } = details;

      return docRef.id;
    });
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

export { addAction };
