import { useState, FormEvent, SyntheticEvent } from "react";
import Link from "next/link";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useRouter } from "next/router";
import { Combobox } from "@headlessui/react";

import { useAuth } from "context/AuthContext";
import ActionItem from "./ActionItem";
import { Action } from "models/Action";
import { USER_VALIDATIONS } from "constants/validationRules";
import { validateURL } from "utils/textUtils";
import {
  MAX_EDIT_ACCOUNT_PER_SECOND,
  SOFT_LIMIT_EDIT_ACCOUNT_PER_SECOND,
} from "constants/hardLimits";
import { addAction } from "pages/api/action";
import { RAY_EMAIL } from "constants/contacts";

const AddAction = () => {
  const { user } = useAuth();
  const router = useRouter();

  // Temp hard limits
  const [firstChange, setFirstChange] = useState(null);
  const [numberOfChanges, setNumberOfChanges] = useState(0);

  const [showActionDetails, setShowActionDetails] = useState(false);
  const [actionTitle, setActionTitle] = useState("");
  const [actionHomeLink, setActionHomeLink] = useState("");
  const [actionHomeLinkEdited, setActionHomeLinkEdited] = useState(false);
  const [actionDescription, setActionDescription] = useState("");
  const [actionDonateLink, setActionDonateLink] = useState("");
  const [actionDonateLinkEdited, setActionDonateLinkEdited] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const handleAddAction = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Temp hard limits
    const saveTime = new Date().getTime();

    if (!firstChange || saveTime - 1000 >= firstChange) {
      setFirstChange(saveTime);
      setNumberOfChanges(1);
    } else {
      if (numberOfChanges >= MAX_EDIT_ACCOUNT_PER_SECOND) {
        toast.error("`Please try again in 10 seconds.`");
        return false;
      } else {
        setNumberOfChanges((changes) => changes + 1);

        if (numberOfChanges >= SOFT_LIMIT_EDIT_ACCOUNT_PER_SECOND) {
          Sentry.captureMessage(
            `Soft limit account edit reached - ${user.uid}`
          );
        }
      }
    }

    const validate = (boolResult: boolean, errorMessage: string): boolean => {
      if (!boolResult) {
        setIsLoading(false);
        toast.error(errorMessage);
        return false;
      }

      return true;
    };

    if (
      !validate(
        actionTitle.length <= USER_VALIDATIONS.titleMaxLength,
        `Your title cannot exceed ${USER_VALIDATIONS.titleMaxLength} characters.`
      )
    )
      return;

    if (
      !validate(
        actionDescription.length <= USER_VALIDATIONS.descriptionMaxLength,
        `Your bio cannot exceed ${USER_VALIDATIONS.descriptionMaxLength} characters.`
      )
    )
      return;

    if (
      actionHomeLink &&
      !validate(validateURL(actionHomeLink), `Your home URL is not valid.`)
    )
      return;

    if (
      actionDonateLink &&
      !validate(validateURL(actionDonateLink), `Your donate URL is not valid.`)
    )
      return;

    await addAction({
      details: {
        authorId: user?.uid || "0",
        title: actionTitle,
        description: actionDescription,
        homeLink: actionHomeLink,
        donateLink: actionDonateLink,
      },
    })
      .then(async () => {
        await fetch("/api/sendgrid", {
          body: JSON.stringify({
            to: RAY_EMAIL,
            from: {
              email: RAY_EMAIL,
              name: user?.uid,
            },
            subject: `New action from ${user?.uid}`,
            text: `Title: ${actionTitle} /n Description: ${actionDescription} /n Home Link: ${actionHomeLink} /n Donate Link: ${actionDonateLink}`,
            html: `<div><p>Title: ${actionTitle}</p><p>Description: ${actionDescription}</p><p>Home Link: ${actionHomeLink}</p><p>Donate Link: ${actionDonateLink}</p></div>`,
          }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        });
        toast.success("Action added! Awaiting approval.");
        setShowActionDetails(false);
        setActionTitle("");
        setActionDescription("");
        setActionHomeLink("");
        setActionDonateLink("");
        setActionHomeLinkEdited(false);
        setActionDonateLinkEdited(false);
      })
      .catch((error) => {
        toast.error(`Error adding action: ${error}`);
      });
  };

  const handleActionTitleChange = (e: FormEvent<HTMLInputElement>) =>
    setActionTitle(e.currentTarget.value);

  const handleActionDescriptionChange = (e: FormEvent<HTMLTextAreaElement>) =>
    setActionDescription(e.currentTarget.value);

  const handleActionHomeLinkChange = (e: FormEvent<HTMLInputElement>) => {
    setActionHomeLinkEdited(true);

    const strippedLink = e.currentTarget.value.replace("https://", "");

    setActionHomeLink(`https://${strippedLink}`);
    if (
      !actionDonateLinkEdited &&
      (actionDonateLink === "" || actionDonateLink === actionHomeLink)
    )
      setActionDonateLink(`https://${strippedLink}`);
  };

  const handleActionDonateLinkChange = (e: FormEvent<HTMLInputElement>) => {
    setActionDonateLinkEdited(true);

    const strippedLink = e.currentTarget.value.replace("https://", "");

    setActionDonateLink(`https://${strippedLink}`);
    if (
      !actionHomeLinkEdited &&
      (actionHomeLink === "" || actionHomeLink === actionDonateLink)
    )
      setActionHomeLink(`https://${strippedLink}`);
  };

  const onAddActionCloseClick = () => setShowActionDetails(false);

  const hasValidNewAction = () =>
    actionTitle && actionDescription && actionHomeLink;

  const handleUnauthenticatedAddAction = () => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, login: "true" },
    });
  };

  if (showActionDetails) {
    return (
      <>
        <div className="mt-20 flex flex-row justify-between">
          <div />
          <div className="pt-4 pl-4 sm:block">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              onClick={onAddActionCloseClick}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <form
          className=" w-full space-y-8"
          action="#"
          method="POST"
          onSubmit={handleAddAction}
        >
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <div className="mt-1">
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                onChange={handleActionTitleChange}
                maxLength={USER_VALIDATIONS.titleMaxLength}
                value={actionTitle.substring(
                  0,
                  USER_VALIDATIONS.titleMaxLength
                )}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="bio"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <div className="mt-1">
              <textarea
                rows={4}
                name="bio"
                id="bio"
                maxLength={USER_VALIDATIONS.bioMaxLength}
                value={actionDescription.substring(
                  0,
                  USER_VALIDATIONS.bioMaxLength
                )}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                onChange={handleActionDescriptionChange}
              />
            </div>
            <div className="mt-1 text-sm text-gray-400">
              Write a few sentences about the action.
            </div>
          </div>

          <div>
            <label
              htmlFor="homeLink"
              className="block text-sm font-medium text-gray-700"
            >
              Home Link
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                https://
              </span>
              <input
                id="homeLink"
                name="homeLink"
                type="text"
                autoComplete="homeLink"
                required
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                onChange={handleActionHomeLinkChange}
                value={actionHomeLink.substring(8, actionHomeLink.length)}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="donateLink"
              className="block text-sm font-medium text-gray-700"
            >
              Donate Link
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                https://
              </span>
              <input
                id="donateLink"
                name="donateLink"
                type="text"
                autoComplete="donateLink"
                required
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                onChange={handleActionDonateLinkChange}
                value={actionDonateLink.substring(8, actionDonateLink.length)}
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={!hasValidNewAction()}
            className="mt-20 flex w-full items-center justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:cursor-not-allowed disabled:bg-emerald-600 disabled:opacity-70"
          >
            Add Action
          </button>
        </form>
      </>
    );
  }

  if (user) {
    return (
      <button
        onClick={() => setShowActionDetails(!showActionDetails)}
        type="button"
        className="mt-20 flex w-full items-center justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 "
      >
        Add Action
      </button>
    );
  }

  return (
    <button
      onClick={handleUnauthenticatedAddAction}
      className="mt-20 flex w-full items-center justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 "
    >
      Add Action
    </button>
  );
};

export default AddAction;
